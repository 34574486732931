<section>
    <div class="header">
        <a href="" class="logo">
            <img src="../../../../assets/fs-logo.svg" alt="">
        </a>

        <h6 class="project-name">{{ projectName }}</h6>
        <h6 class="project-name-label">Project Portal</h6>
    </div>

    <nav>
        <ul>
            <li *ngFor="let menu of navMenus; index as i"
                [ngClass]="{'active' : menu.active, 'has-children' : menu.children?.length > 0, 'locked' : menu.locked, 'opened' : menu.isOpen}">
                <a (click)="menuClick(menu, i)">
                    <i class="icon" [ngClass]="menu.icon"></i>
                    <i class="arrow"></i>
                    <label>{{ menu.name }}</label>
                    <i class="lock"></i>
                </a>
                <div class="child-nav" [style.height]="menu.childHeight">
                    <ul #childNav>
                        <li *ngFor="let child of menu.children" [ngClass]="{'locked' : child.locked}">
                            <a>
                                <label for="">{{ child.name }}</label>
                                <i class="lock"></i>
                            </a>
                        </li>
                    </ul>
                </div>
            </li>
        </ul>
    </nav>

    <div class="footer">
        <div class="profile-panel">
            <div class="media">
                <button (click)="uploadProfileImage()">
                    <img *ngIf="userAvatar" [src]="userAvatar" alt="">
                </button>
            </div>
            <div class="user-info">
                <h3>{{ userFullName }}</h3>
                <h6>{{ userEmail }}</h6>
            </div>
            <div class="actions">
                <button (click)="logOut()">
                    <img src="../../../../assets/menu/fs-logout.svg" alt="">
                </button>
            </div>
        </div>
    </div>
</section>