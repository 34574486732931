import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { Router } from '@angular/router';
import { from } from 'rxjs';
import { BillingService } from 'src/app/app-billing/shared/services/billing.service';
import { SessionService } from 'src/app/core/shared/services/session.service';

@Component({
  selector: 'app-fs-payment-form',
  templateUrl: './fs-payment-form.component.html',
  styleUrls: ['./fs-payment-form.component.scss']
})
export class FsPaymentFormComponent implements OnInit {

  @Input('vertical') vertical: boolean;
  @Input('viewState') viewState: string;
  @Input('inlineEditing') inlineEditing: boolean;

  @Output('onPaymentSave') onPaymentSave: EventEmitter<any> = new EventEmitter();

  form: FormGroup;
  isLoading: boolean;

  constructor(
    private formBuilder: FormBuilder,
    private billingService: BillingService,
    private sessionService: SessionService,
    private router: Router
  ) { }

  ngOnInit(): void {
    this.buildForm();
    if (!!this.sessionService.currentUser.source) {
      this.fillForm();
    }
  }


  cardChange(e) {
    this.form.patchValue({
      card: {
        numberIsValid: e.cardNumberIsValid,
        expIsValid: e.expIsValid,
        cvcIsValid: e.cvcIsValid,
        stripeElements: e.stripeElements,
        stripe: e.stripe
      }
    });
  }

  editPaymentDetails() {
    if (this.inlineEditing) {
      this.viewState = 'edit';
    } else {
      this.router.navigate(['/billing/edit']);
    }
  }

  save() {
    this.submit(this.form);
  }

  private fillForm() {
    this.billingService.getBillingCard()
      .subscribe((resp) => {
        this.form.patchValue({
          card: {
            cardNumberLastDigits: resp.cardNumberLastDigits,
            cardHolderName: resp.cardHolderName,
            cardExpiry: `${resp.expMonth}/${resp.expYear}`
          },
          address: {
            name: resp.addressName,
            addressLineOne: resp.addressLineOne,
            addressLineTwo: resp.addressLineTwo,
            state: resp.addressState,
            country: resp.addressCountry
          }
        })

      }, err => { });
  }

  private submit({ value, valid }: { value: any, valid: boolean }) {
    if (valid) {
      this.isLoading = true;

      let stripe = value.card.stripe;
      let stripeElements = value.card.stripeElements;

      let cardHolderName = value.card.cardHolderName;
      let addressName = value.address.name;
      let addressLineOne = value.address.addressLineOne;
      let addressLineTwo = value.address.addressLineTwo;
      let addressState = value.address.state;
      let addressCountry = value.address.country;

      from(stripe.createToken(stripeElements.getElement('cardNumber'), {
        name: cardHolderName,
        address_name: addressName,
        address_line1: addressLineOne,
        address_line2: addressLineTwo,
        address_state: addressState,
        address_country: addressCountry
      }))
        .subscribe((resp: any) => {
          this.billingService.createBillingCard(resp.token?.id)
            .subscribe((sourceId) => {
              this.sessionService.savePaymentSource(sourceId);
              if (this.inlineEditing) {
                this.viewState = 'view';
              } else {
                this.router.navigate(['/billing']);
              }
              this.isLoading = false;
            }, err => {
              this.isLoading = false;
            });
        })
    }
  }

  private buildForm() {
    this.form = this.formBuilder.group({
      card: this.formBuilder.group({
        numberIsValid: [null, [Validators.requiredTrue]],
        cardHolderName: [null, [Validators.required]],
        expIsValid: [null, [Validators.requiredTrue]],
        cvcIsValid: [null, [Validators.requiredTrue]],
        stripeElements: [null, [Validators.required]],
        stripe: [null, [Validators.required]],

        cardNumberLastDigits: [],
        cardExpiry: []
      }),
      address: this.formBuilder.group({
        name: [null, [Validators.required]],
        addressLineOne: [null, [Validators.required]],
        addressLineTwo: [null, [Validators.required]],
        state: [null, [Validators.required]],
        country: [null, [Validators.required]]
      })
    })
  }
}
