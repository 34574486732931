export function getCurrentOrigin() {
    return location.origin;
}

export function getCurrentDomain() {
    return getCurrentOrigin().replace("https://", "").replace("http://", "");
}

export function getCurrentProtocol() {
    return getCurrentOrigin().replace(getCurrentDomain(), "");
}

export function getUrlForProject(projectSlug: string) {
    let currentDomain = getCurrentDomain();
    let isLocalhost = currentDomain.indexOf('localhost') > -1;
    return `${getCurrentProtocol()}${isLocalhost ? '' : `${projectSlug}/`}${getCurrentDomain()}`;
}

export function getSlug() {
    let currentDomain = getCurrentDomain();
    let isLocalhost = currentDomain.indexOf('localhost') > -1;
    if (isLocalhost) { return ''; }
    return currentDomain.split('.')[0];
}