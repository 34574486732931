<image-cropper [imageChangedEvent]="imageChangedEvent" [maintainAspectRatio]="true" [aspectRatio]="1 / 1" format="png"
    (imageCropped)="imageCropped($event)" [roundCropper]="true" [hideResizeSquares]="false" [transform]="transform">
</image-cropper>

<div class="image-selector" style="display: none;">
    <input type="file" (change)="fileChangeEvent($event)" #fileSelector />
</div>

<div class="image-scale-container">
    <div class="scale-min"> 50% </div>
    <div class="scale-range">
        <app-fs-range [min]="-2" [max]="2" [value]="0" [showRangeProgress]="false" (onChange)="scaleChange($event)">
        </app-fs-range>
    </div>
    <div class="scale-max"> 200% </div>
</div>