import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';

@Component({
  selector: 'app-fs-checkbox',
  templateUrl: './fs-checkbox.component.html',
  styleUrls: ['./fs-checkbox.component.scss']
})
export class FsCheckboxComponent implements OnInit {

  @Output('onChange') onChange: EventEmitter<any> = new EventEmitter<any>();
  @Input('checked') checked: boolean;

  constructor() { }

  ngOnInit(): void {
  }

  change(event) {
    this.onChange.emit(event);
  }
}
