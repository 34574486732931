import { Component, ElementRef, OnDestroy, OnInit, QueryList, ViewChildren } from '@angular/core';
import { Router } from '@angular/router';
import { ReplaySubject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { AuthService } from 'src/app/app-auth/shared/services/auth.service';
import { ProfileDataService } from 'src/app/app-profile/shared/services/profile-data.service';
import { ModalService } from 'src/app/shared/services/modal.service';
import { SessionService } from '../../shared/services/session.service';

@Component({
  selector: 'app-nav-menu',
  templateUrl: './nav-menu.component.html',
  styleUrls: ['./nav-menu.component.scss']
})
export class NavMenuComponent implements OnInit, OnDestroy {

  private destroyed$: ReplaySubject<boolean> = new ReplaySubject(1);

  projectName: string;

  userFullName: string;
  userEmail: string;
  userAvatar: string;

  navMenus = [
    { name: 'Home', icon: 'home', locked: false, active: false, link: ['/'] },
    { name: 'Task List', icon: 'task-list', locked: false, active: true, link: ['/tasks'] },
    {
      name: 'Strategy', icon: 'strategy', locked: false, active: false,
      children: [
        { name: 'Brief Summary', locked: false },
        { name: 'Sitemap', locked: false },
        { name: 'User Personas', locked: false },
        { name: 'Wireframes', locked: true }
      ]
    },
    { name: 'Design', icon: 'design', locked: true, active: false, children: [{}] },
    { name: 'Engineering', icon: 'engineering', locked: true, active: false, children: [{}] },
    { name: 'Billing', icon: 'billing', locked: false, active: false, link: ['/billing'] },
  ];

  @ViewChildren("childNav") childNavs: QueryList<ElementRef>;

  constructor(
    private authService: AuthService,
    private sessionService: SessionService,
    private modalService: ModalService,
    private router: Router,
    private profileDataService: ProfileDataService
  ) { }

  ngOnInit(): void {
    this.userFullName = this.sessionService.currentUser.fullName;
    this.userEmail = this.sessionService.currentUser.email;
    this.projectName = this.sessionService.currentUser.project.name;
    this.userAvatar = this.sessionService.currentUser.avatar;

    this.profileDataService.avatar
      .pipe(takeUntil(this.destroyed$))
      .subscribe(() => {
        console.log('');
        console.log('image changed');
        this.userAvatar = this.sessionService.currentUser.avatar;
      })
  }

  ngOnDestroy() {
    this.destroyed$.next(true);
    this.destroyed$.complete();
  }

  menuClick(menu, index) {
    if (menu.children?.length > 0 && !menu.locked) {
      menu.isOpen = !menu.isOpen;
      menu.childHeight = `${menu.isOpen ? this.childNavs.find((_, i) => i == index).nativeElement.offsetHeight + 15 : 0}px`;
    } else {
      this.router.navigate(menu.link);
    }
  }

  uploadProfileImage() {
    this.modalService
      .showModal(import('../../../app-profile/components/upload-image/upload-image.component'))
      .subscribe();
  }

  logOut() {
    this.sessionService.removeSession();
    this.router.navigate(['auth/login']);
  }
}
