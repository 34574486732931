<section class="card-box">
    <h6>{{ label }}</h6>
    <div class="card" [ngClass]="{'edit' : isEdit}">
        <button *ngIf="!isEdit" class="btn-edit" (click)="redirectToEdit()"></button>

        <div *ngIf="!isEdit">
            <div class="card-logo">
                <div class="card-logo-strip"></div>
                <div class="card-logo-strip"></div>
                <div class="card-logo-strip"></div>
            </div>

            <div class="card-number">
                <div class="card-number-couple">****</div>
                <div class="card-number-couple">****</div>
                <div class="card-number-couple">****</div>
                <div class="card-number-couple">{{ form.get('cardNumberLastDigits').value }}</div>
            </div>

            <div class="card-holder-and-valid-to-date">
                <div class="card-holder">{{ form.get('cardHolderName').value }}</div>
                <div class="card-valid-to">{{ form.get('cardExpiry').value }}</div>
            </div>
        </div>

        <div *ngIf="isEdit">
            <label>Card Number</label>

            <form [formGroup]="form">
                <div class="card-number">
                    <!-- <app-fs-card-number></app-fs-card-number> -->
                    <div id="card-number"></div>
                </div>

                <div class="card-holder-exp-date-and-cvc">
                    <div class="card-holder">
                        <app-fs-text-input [label]="'Cardholder name'" formControlName="cardHolderName">
                        </app-fs-text-input>
                    </div>
                    <div class="exp-date">
                        <label for="">EXP</label>
                        <!-- <app-fs-text-input [label]="'EXP'" formControlName="exp"></app-fs-text-input> -->
                        <div id="card-exp"></div>
                    </div>
                    <div class="cvc">
                        <label for="">CVC</label>
                        <!-- <app-fs-text-input [label]="'CVC'" formControlName="cvc"></app-fs-text-input> -->
                        <div id="card-cvc"></div>
                    </div>
                </div>
            </form>

        </div>
    </div>
</section>