<section>
    <div class="auth-layout-container">
        <div class="auth-header">
            <a href="">
                <img src="../../../../assets/fs-logo-lines.svg" alt="">
            </a>
            <div class="project-portal-name">{{ projectName }}</div>
            <div class="project-portal">Project Portal</div>
        </div>
        <div class="auth-body">
            <router-outlet></router-outlet>
        </div>
    </div>
    <div class="auth-cover-container">
        <div class="line-patterns">
            <div class="line-patterns-layout">
                <div class="line-pattern"></div>
                <div class="line-pattern"></div>
                <div class="line-pattern"></div>
            </div>
        </div>
        <div class="cover-panel">
            <div>
                <span> Big </span>
                <span> Ideas. </span>
                <span> Built </span>
                <span> Fast. </span>
            </div>
            <a href="" class="logo">
                <img src="../../../../assets/fs-logo.svg" alt="">
            </a>
        </div>
    </div>
</section>