import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-fs-card-number',
  templateUrl: './fs-card-number.component.html',
  styleUrls: ['./fs-card-number.component.scss']
})
export class FsCardNumberComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {
  }

}
