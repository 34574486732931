<div class="info-box">
    <div class="info-heading" [ngClass]="{'expandable' : expandable}">
        <span class="title">from</span>
        <span class="value">${{ totalPrice }}</span>
    </div>

    <div class="info-details" [style.height]="infoDetailsHeight" [ngClass]="{'expanded' : expanded}">
        <div class="info-details-content" #infoDetailsContent>
            <div class="details-table">
                <div class="details-table-row header">
                    <h6 class="type">Type</h6>
                    <h6 class="sprint">Sprints</h6>
                    <h6 class="budget">Budget</h6>
                </div>
                <div class="details-table-row" *ngFor="let timeline of timelines">
                    <h6 class="type">{{ timeline.sprintName }}</h6>
                    <h6 class="sprint">{{ timeline.noOfSprints }}</h6>
                    <h6 class="budget">${{ timeline.budget }}</h6>
                </div>
            </div>
        </div>
    </div>

    <div class="info-footer" *ngIf="expandable">
        <button (click)="toggleDetails()" [ngClass]="{'opened' : expanded}">
            <img src="../../../../assets/fs-triangle.svg" alt="">
            <span *ngIf="!expanded">Expand</span>
            <span *ngIf="expanded">Hide</span>
        </button>
    </div>
</div>