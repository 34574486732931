import { Injectable } from '@angular/core';
import { HttpRequest, HttpHandler, HttpEvent, HttpInterceptor } from '@angular/common/http';
import { Observable } from 'rxjs';
import { SessionService } from '../shared/services/session.service';

@Injectable()
export class TokenInterceptor implements HttpInterceptor {
    constructor(public sessionService: SessionService) { }
    intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
        let token = this.sessionService?.currentUser?.token
        if (request.url.indexOf('s3') == -1 && token) {
            request = request.clone({ setHeaders: { Authorization: `Bearer ${token}` } });
        }
        return next.handle(request);
    }
}