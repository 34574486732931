import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { from, fromEvent, Observable } from 'rxjs';
import { catchError, map } from 'rxjs/operators';
import { SessionService } from 'src/app/core/shared/services/session.service';
import { handleError } from 'src/app/shared/utils/utils';
import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root'
})
export class AuthService {

  private baseUrl = `${environment.apiUrl}/auth`;

  constructor(
    private http: HttpClient,
    private sessionService: SessionService
  ) { }

  login(email: string, password: string): Observable<boolean> {
    const url = `${this.baseUrl}/local`;
    return this.http.post(url, {
      email: email,
      password: password
    }).pipe(
      map((resp: any) => {
        if (resp && resp.token) {
          this.sessionService.saveSession(resp.token);
          return true;
        } return false;
      }),
      catchError(handleError)
    )
  }

  register(
    email: string, fullName: string, projectName: string,
    estimateId: string,
    serviceStrategy: boolean, serviceDesign: boolean, serviceEngineering: boolean,
    priorityBudget: boolean, priorityFeatures: boolean, prioritySpeed: boolean):
    Observable<string> {

    const url = `${this.baseUrl}/local/register`;

    let data = {
      email: email,
      name: fullName,
      project: {
        estimate: estimateId,
        name: projectName,
        servicesIncluded: {
          strategy: serviceStrategy,
          design: serviceDesign,
          engineering: serviceEngineering
        },
        priority: {
          budget: priorityBudget,
          features: priorityFeatures,
          speed: prioritySpeed
        }
      },
    };

    return this.http.post(url, data)
      .pipe(
        map((resp: any) => { return resp.slug; }),
        catchError(handleError)
      );
  }

  registerFromInvitation(token: string, email: string, fullName: string): Observable<void> {
    const url = `${this.baseUrl}/local/register`;
    let data = {
      invitationToken: token,
      email: email,
      name: fullName
    };
    return this.http.post(url, data)
      .pipe(
        map((resp: any) => { console.log(resp); return; }),
        catchError(handleError)
      );
  }

  requestJoin(email: string): Observable<void> {
    const url = `${this.baseUrl}/local/register/request`;
    let data = { email: email, };
    return this.http.post(url, data)
      .pipe(
        map((resp: any) => { return; }),
        catchError(handleError)
      );
  }

  resendPassword(email: string): Observable<void> {
    const url = `${this.baseUrl}/local`;
    return this.http.post(url, { email: email })
      .pipe(
        map((resp: any) => { return; }),
        catchError(handleError)
      )
  }

  refreshToken(): Observable<void> {
    const url = `${this.baseUrl}/refresh-token`;
    return this.http.post(url, {})
      .pipe(
        map((resp: any) => {
          console.log(resp);
          return;
        }),
        catchError(handleError)
      )
  }

  logout(): Observable<void> {
    return from([1])
      .pipe(
        map(() => { return; }),
        catchError(handleError)
      );
  }
}
