<div class="progress-bar">
    <div class="progress-line-panel">
        <div class="progress-line">
            <div class="progress-line-filled" [style.width]="progressWidth"
                [ngClass]="{'full' : currentStep == totalSteps}">

            </div>
        </div>

        <img src="../../../../assets/fs-flag.svg" alt="">
    </div>
    <div class="progress-nav">
        <a (click)="back()"> <img src="../../../../assets/fs-arrow-left.svg" alt=""> Go Back</a>
        <a (click)="skip()">Skip <img src="../../../../assets/fs-arrow-left.svg" alt=""></a>
    </div>
</div>