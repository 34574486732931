import { EventEmitter, Injectable, Output } from '@angular/core';
import { fromEvent } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class EventService {
  @Output('documentClick') documentClick: EventEmitter<any> = new EventEmitter<any>();
  @Output('closeDropDown') closeDropDown: EventEmitter<any> = new EventEmitter<any>();

  constructor() {
    fromEvent(document, 'click')
      .subscribe((e) => {
        this.documentClick.emit(e);
      });

  }
}
