<div class="intensity-range">
    <h5 class="from">{{ from }}</h5>

    <div class="range-panel">
        <input type="range" class="range" [min]="min" [max]="max" step="1" [value]="value" (input)="oninput($event)">
        <div class="range-slides">
            <div class="range-slide">
                <div class="slide-progress" [style.width]="leftProgressWidth" [ngClass]="{'full' : leftFull}"></div>
            </div>
            <div class="range-slide">
                <div class="slide-progress" [style.width]="rightProgressWidth" [ngClass]="{'full' : rightFull}"></div>
            </div>
        </div>
    </div>

    <h5 class="to">{{ to }}</h5>
</div>