import { Component, ElementRef, Input, OnChanges, OnInit, ViewChild } from '@angular/core';
import { IProjectTimeline } from 'src/app/core/shared/models/project.model';

@Component({
  selector: 'app-fs-budget-info',
  templateUrl: './fs-budget-info.component.html',
  styleUrls: ['./fs-budget-info.component.scss']
})
export class FsBudgetInfoComponent implements OnInit, OnChanges {
  @Input('expandable') expandable: boolean;
  @Input('expanded') expanded: boolean;
  @Input('timelines') timelines: IProjectTimeline[];

  infoDetailsHeight: string;

  @ViewChild('infoDetailsContent') infoDetailsContent: ElementRef;

  get totalPrice(): number {
    return this.timelines?.reduce((a, b) => a + (b['budget'] || 0), 0);
  }

  constructor() { }

  ngOnInit(): void {
    if (this.expanded) {
      setTimeout(() => {
        this.maintainExpanded();
      }, 500);
    }
  }

  ngOnChanges() {
    setTimeout(() => { this.maintainExpanded(); }, 10);
  }

  toggleDetails() {
    this.expanded = !this.expanded;
    this.maintainExpanded();
  }

  private maintainExpanded() {
    if (this.expanded) {
      this.infoDetailsHeight = `${this.infoDetailsContent?.nativeElement.offsetHeight + 50}px`
    } else {
      this.infoDetailsHeight = '';
    }
  }
}
