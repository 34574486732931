import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FsTextInputComponent } from './components/fs-text-input/fs-text-input.component';
import { FsEmailInputComponent } from './components/fs-email-input/fs-email-input.component';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { FsSkewButtonComponent } from './components/fs-skew-button/fs-skew-button.component';
import { FsCheckboxComponent } from './components/fs-checkbox/fs-checkbox.component';
import { FsIntensityRangeComponent } from './components/fs-intensity-range/fs-intensity-range.component';
import { FsRangeComponent } from './components/fs-range/fs-range.component';
import { FsDropdownMenuComponent } from './components/fs-dropdown-menu/fs-dropdown-menu.component';
import { FsProgressLineComponent } from './components/fs-progress-line/fs-progress-line.component';
import { FsCardComponent } from './components/fs-card/fs-card.component';
import { FsLoaderComponent } from './components/fs-loader/fs-loader.component';
import { FsCardNumberComponent } from './components/fs-card-number/fs-card-number.component';
import { FsBudgetInfoComponent } from './components/fs-budget-info/fs-budget-info.component';
import { FsCircularRangeComponent } from './components/fs-circular-range/fs-circular-range.component';
import { ImageCropperModule } from 'ngx-image-cropper';
import { FsImageCropperComponent } from './components/fs-image-cropper/fs-image-cropper.component';
import { RouterModule } from '@angular/router';
import { FsPaymentFormComponent } from './components/fs-payment-form/fs-payment-form.component';



@NgModule({
  declarations: [
    FsTextInputComponent,
    FsEmailInputComponent,
    FsSkewButtonComponent,
    FsCheckboxComponent,
    FsIntensityRangeComponent,
    FsRangeComponent,
    FsDropdownMenuComponent,
    FsProgressLineComponent,
    FsCardComponent,
    FsLoaderComponent,
    FsCardNumberComponent,
    FsBudgetInfoComponent,
    FsCircularRangeComponent,
    FsImageCropperComponent,
    FsPaymentFormComponent
  ],
  imports: [
    CommonModule,
    FormsModule,
    ReactiveFormsModule,
    ImageCropperModule,
  ],
  exports: [
    FsEmailInputComponent,
    FsTextInputComponent,
    FsSkewButtonComponent,
    FsCheckboxComponent,
    FsIntensityRangeComponent,
    FsRangeComponent,
    FsDropdownMenuComponent,
    FsProgressLineComponent,
    FsCardComponent,
    FsPaymentFormComponent,
    FsBudgetInfoComponent,
    FsLoaderComponent,
    FsCircularRangeComponent,
    FsImageCropperComponent,

    CommonModule,
    ReactiveFormsModule,
    FormsModule,
    // DecimalPipe
  ]
})
export class SharedModule { }
