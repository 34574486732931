<div class="card-number-panel">
    <input type="text">
    <input type="text">
    <input type="text">
    <input type="text">
</div>

<!-- <app-fs-text-input></app-fs-text-input>
                    <app-fs-text-input></app-fs-text-input>
                    <app-fs-text-input></app-fs-text-input>
                    <app-fs-text-input></app-fs-text-input> -->