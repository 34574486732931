<section>
    <div class="circular-range-container">
        <div class="circular-range-panel">
            <div class="label label-one" [ngClass]="{'active' : featuresIsActive}">
                <h5>Features</h5>
            </div>

            <div class="range-virtual-circle">
                <div class="circle circle-one">
                    <svg class="progress-ring" width="100%" height="100%">
                        <circle fill="transparent" />
                    </svg>
                </div>

                <div class="circle circle-two">
                    <svg class="progress-ring" width="100%" height="100%">
                        <circle fill="transparent" />
                    </svg>
                </div>

                <div class="circle circle-three">
                    <svg class="progress-ring" width="100%" height="100%">
                        <circle fill="transparent" />
                    </svg>
                </div>

                <div class="circle-inner" (mousedown)="circleMouseDown($event)" #circle>
                    <div class="circle-inside">
                        <div class="picker" [style.transform]="myRotation">
                            <div class="picker-panel">
                                <div class="picker-up">
                                    <img src="../../../../assets/arc-1.svg" alt="">
                                </div>

                                <div class="picker-thumb" #pickerThumb [style.transform]="thumbRotation"
                                    (mousedown)="pickerCircleMouseDown($event)">
                                    <div class="track-line"></div>
                                    <div class="track-line"></div>
                                    <div class="track-line"></div>
                                </div>

                                <div class="picker-down">
                                    <img src="../../../../assets/arc-1.svg" alt="">
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            <div class="label label-two" [ngClass]="{'active' : budgetIsActive}">
                <h5>Budget</h5>
            </div>

            <div class="label full-label" [ngClass]="{'active' : sppedIsActive}">
                <h5>Speed</h5>
            </div>
        </div>
    </div>
</section>