import { Injectable } from '@angular/core';
import { ISessionUser } from '../models/session-user.model';
import jwt_decode from "jwt-decode";
import { utils } from 'protractor';
import { getSlug, getUrlForProject } from '../utils/utils';

@Injectable({
  providedIn: 'root'
})
export class SessionService {
  private tokenKey = 'auth_token';
  private tokenExpireDateKey = 'auth_token_exp';
  private avatarKey = 'user_avatar';

  private paymentSourceKey = 'payment_source';

  constructor() { }

  get currentUser(): ISessionUser {
    let session = this.getSession();
    if (session) {
      let sessionValue = jwt_decode(session, {}) as any;
      let slug = getSlug();

      return {
        id: sessionValue.id,
        token: session,
        email: sessionValue.email,
        fullName: sessionValue.name,
        avatar: localStorage.getItem(this.avatarKey),
        source: localStorage.getItem(this.paymentSourceKey) ?? sessionValue.source,
        questionnaire: {
          id: sessionValue.questionnaire.id,
          completed: sessionValue.questionnaire.completed,
          step: sessionValue.questionnaire.step
        },
        project: {
          id: sessionValue.project.id,
          name: sessionValue.project.name,
          slug: !!slug ? slug : 'ace',
          isAdmin: sessionValue.project.isAdmin
        },
        invoice: {
          id: sessionValue.invoice.id,
          status: sessionValue.invoice.status,
          paid: sessionValue.invoice.paid
        }
      } as ISessionUser;
    }
    return null;
  }

  get isAuthenticated(): boolean {
    let session = this.getSession();
    let isAuthenticated = !!session && !this.sessionIsExpired();

    if (!isAuthenticated) {
      if (session) {
        this.removeSession();
        localStorage.removeItem(this.paymentSourceKey);
      }
    }

    return isAuthenticated;
  }

  saveSession(token: string) {
    localStorage.setItem(this.tokenKey, token);
    localStorage.setItem(this.tokenExpireDateKey, (jwt_decode(token) as any).exp);
  }

  removeSession() {
    localStorage.removeItem(this.tokenKey);
    localStorage.removeItem(this.tokenExpireDateKey);
    localStorage.removeItem(this.paymentSourceKey);
    localStorage.removeItem(this.avatarKey);
  }

  savePaymentSource(paymentSource: string) {
    localStorage.setItem(this.paymentSourceKey, paymentSource);
  }

  saveAvatar(avatar: string) {
    localStorage.setItem(this.avatarKey, avatar);
  }

  private sessionIsExpired(): boolean {
    let sessionExpireDate = this.getSessionExpireDate();
    if (sessionExpireDate == '') { return true; }

    return this.currentUtcEpoch() > +sessionExpireDate;
  }

  private currentUtcEpoch(): number {
    return Date.UTC(new Date().getUTCFullYear(), new Date().getUTCMonth(), new Date().getUTCDate(), new Date().getUTCHours(), new Date().getUTCMinutes(), new Date().getUTCSeconds(), new Date().getUTCMilliseconds()) / 1000;
  }

  private getSessionExpireDate() {
    return localStorage.getItem(this.tokenExpireDateKey);
  }

  private getSession() {
    return localStorage.getItem(this.tokenKey);
  }
}
