import { Component, ElementRef, EventEmitter, OnInit, Output, ViewChild } from '@angular/core';
import { ImageCroppedEvent, ImageTransform } from 'ngx-image-cropper';

@Component({
  selector: 'app-fs-image-cropper',
  templateUrl: './fs-image-cropper.component.html',
  styleUrls: ['./fs-image-cropper.component.scss']
})
export class FsImageCropperComponent implements OnInit {

  imageChangedEvent: any = '';
  croppedImage: any = '';

  scale = 1;
  transform: ImageTransform = {};

  private fileType: string;
  private file: any;

  @Output('onImageSelect') onImageSelect: EventEmitter<void> = new EventEmitter();

  @ViewChild('fileSelector') fileSelector: ElementRef;

  constructor() { }

  ngOnInit(): void {
  }

  selectFile() {
    this.fileSelector.nativeElement.click();
  }

  clearFile() {
    this.fileSelector.nativeElement.value = null;
    this.fileType = null;
  }

  fileChangeEvent(event: any): void {
    this.imageChangedEvent = event;
    this.onImageSelect.emit();
    let files = event.target.files;
    if (files.length > 0) {
      let file = files[0];
      this.fileType = file.type;
    }
  }

  getFile() {
    return {
      file: this.dataURLtoFile(this.croppedImage, ''),
      fileType: this.fileType
    };
  }

  imageCropped(event: ImageCroppedEvent) {
    this.croppedImage = event.base64;
  }

  scaleChange(e) {
    if (this.croppedImage) {
      this.scale += (e * 0.1);
      if (e == 0) { this.scale = 1; }
      this.transform = {
        ...this.transform,
        scale: this.scale
      };
    }
  }

  private dataURLtoFile(dataurl, filename) {

    var arr = dataurl.split(','),
      mime = arr[0].match(/:(.*?);/)[1],
      bstr = atob(arr[1]),
      n = bstr.length,
      u8arr = new Uint8Array(n);

    while (n--) {
      u8arr[n] = bstr.charCodeAt(n);
    }

    return new File([u8arr], filename, { type: mime });
  }
}
