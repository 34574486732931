import { Component, ElementRef, EventEmitter, Input, OnChanges, OnInit, Output, QueryList, SimpleChange, SimpleChanges, ViewChildren } from '@angular/core';

@Component({
  selector: 'app-fs-intensity-range',
  templateUrl: './fs-intensity-range.component.html',
  styleUrls: ['./fs-intensity-range.component.scss']
})
export class FsIntensityRangeComponent implements OnInit, OnChanges {

  @Input('from') from: string;
  @Input('to') to: string;

  @Input('min') min: number;
  @Input('max') max: number;

  @Input('value') value: number;

  @Output('onChange') onChange: EventEmitter<number> = new EventEmitter<number>();

  leftProgressWidth: string;
  rightProgressWidth: string;

  leftFull = false;
  rightFull = false;

  @ViewChildren('intensityLabel') intensityLabels: QueryList<ElementRef>;

  constructor() { }

  ngOnInit(): void {
    setTimeout(() => { this.updateSlider(this.value); }, 1);
  }

  ngOnChanges(changes: SimpleChanges) {
    if (changes.value.previousValue != changes.value.currentValue) {
      this.updateSlider(this.value);
    }
  }

  oninput(e) {
    this.updateSlider(e.target.value);
    this.onChange.emit(e.target.value);
  }

  updateSlider(value) {
    this.leftProgressWidth = '0px';
    this.rightProgressWidth = '0px';

    this.rightFull = false;
    this.leftFull = false;

    let progressPercent = value * 100 / ((this.max - this.min) / 2);

    if (value > 0) { this.rightProgressWidth = `${progressPercent}%`; this.rightFull = (progressPercent == 100); }
    if (value < 0) { this.leftProgressWidth = `${progressPercent * -1}%`; this.leftFull = (progressPercent == -100); }
  }
}
