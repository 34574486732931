// This file can be replaced during build by using the `fileReplacements` array.
// `ng build --prod` replaces `environment.ts` with `environment.prod.ts`.
// The list of file replacements can be found in `angular.json`.

export const environment = {
  production: false,
  apiUrl: 'https://fs-wheel-admin-staging.herokuapp.com',
  paymentApiUrl: 'https://fs-wheel-function-payment.herokuapp.com',
  stripeKey: 'pk_test_51ITrmTDWPf2enLeu6enSm5WiMs2st8RyPebXJaAGoAeRW3sQBP44MHngFSUU6B6BFS22CugjKzU7Q7EmO396sYVw00bEOWD6Hd'
};

/*
 * For easier debugging in development mode, you can import the following file
 * to ignore zone related error stack frames such as `zone.run`, `zoneDelegate.invokeTask`.
 *
 * This import should be commented out in production mode because it will have a negative impact
 * on performance if an error is thrown.
 */
// import 'zone.js/dist/zone-error';  // Included with Angular CLI.
