import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-fs-loader',
  templateUrl: './fs-loader.component.html',
  styleUrls: ['./fs-loader.component.scss']
})
export class FsLoaderComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {
  }

}
