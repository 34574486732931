import { EventEmitter, Injectable, Output } from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class FadeService {
  @Output('onShow') onShow: EventEmitter<void> = new EventEmitter();
  @Output('onHide') onHide: EventEmitter<void> = new EventEmitter();

  constructor() { }

  show() {
    this.onShow.emit();
  }

  hide() {
    this.onHide.emit();
  }
}
