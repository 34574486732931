import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { AuthLayoutComponent } from './core/layouts/auth-layout/auth-layout.component';
import { SiteLayoutComponent } from './core/layouts/site-layout/site-layout.component';
import { AuthGuard } from './core/shared/guards/auth.guard';


const childRoutes: Routes = [
  { path: '', loadChildren: () => import('./app-main/app-main.module').then(m => m.AppMainModule) },
  { path: 'questionnaire', loadChildren: () => import('./app-questionnaire/app-questionnaire.module').then(m => m.AppQuestionnaireModule) },
  { path: 'pricing-estimator', loadChildren: () => import('./app-pricing-estimator/app-pricing-estimator.module').then(m => m.AppPricingEstimatorModule) },
  { path: 'billing', loadChildren: () => import('./app-billing/app-billing.module').then(m => m.AppBillingModule) },
  { path: 'checkout', loadChildren: () => import('./app-checkout/app-checkout.module').then(m => m.AppCheckoutModule) },
  { path: 'tasks', loadChildren: () => import('./app-task/app-task.module').then(m => m.AppTaskModule) },
];

const childAuthRoutes: Routes = [
  { path: '', loadChildren: () => import('./app-auth/app-auth.module').then(m => m.AppAuthModule) },
];

const routes: Routes = [
  {
    path: '',
    component: SiteLayoutComponent,
    children: childRoutes,
    canActivate: [AuthGuard]
  },
  {
    path: 'auth',
    component: AuthLayoutComponent,
    children: childAuthRoutes
  },
  {
    path: '**',
    redirectTo: '/'
  }
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule]
})
export class AppRoutingModule { }
