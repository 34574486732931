import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { catchError, map } from 'rxjs/operators';
import { SessionService } from 'src/app/core/shared/services/session.service';
import { handleError } from 'src/app/shared/utils/utils';
import { environment } from 'src/environments/environment';
import { IBillingCard } from '../models/billing-card.model';

@Injectable({
  providedIn: 'root'
})
export class BillingService {

  private baseUrl = `${environment.paymentApiUrl}/payment-methods`

  constructor(
    private http: HttpClient,
    private sessionService: SessionService
  ) { }

  getBillingCard(): Observable<IBillingCard> {
    let sourceId = this.sessionService.currentUser.source;
    const url = `${this.baseUrl}/${sourceId}`;
    return this.http.get(url)
      .pipe(map((resp: any) => {
        return {
          cardHolderName: resp.name,
          cardNumberLastDigits: resp.last4,
          expYear: resp.exp_year,
          expMonth: resp.exp_month,

          addressName: resp.addressName,
          addressLineOne: resp.address_line1,
          addressLineTwo: resp.address_line2,
          addressCountry: resp.address_country,
          addressState: resp.address_zip
        } as IBillingCard;
      }), catchError(handleError))
  }

  createBillingCard(stripeCardToken: string): Observable<string> {
    const url = `${this.baseUrl}`;
    return this.http.post(url, { source: stripeCardToken })
      .pipe(
        map((resp: any) => { return resp.id; }),
        catchError(handleError)
      )
  }  
}
