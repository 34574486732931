import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';

@Component({
  selector: 'app-fs-range',
  templateUrl: './fs-range.component.html',
  styleUrls: ['./fs-range.component.scss']
})
export class FsRangeComponent implements OnInit {

  @Input('showRangeProgress') showRangeProgress: boolean = true;
  @Input('label') label: string;
  @Input('min') min: number = 0;
  @Input('max') max: number = 10;
  @Input('value') value: number = 1;

  @Output('onChange') onChange: EventEmitter<any> = new EventEmitter();

  isFull = false;

  progressWidth = '';

  constructor() { }

  ngOnInit(): void {
    setTimeout(() => { this.updateSlider(this.value); }, 10);
  }

  oninput(e) {
    this.updateSlider(e.target.value);
  }

  updateSlider(value) {
    this.progressWidth = '0px';
    this.isFull = false;
    let steps = this.max - this.min;

    let progressPercent = value * 100 / steps;

    this.isFull = (progressPercent == 100)
    this.progressWidth = `${progressPercent}%`;

    this.onChange.emit(value);
  }
}
